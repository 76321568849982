function Header() {
  return (
    <header>
      <video src="video.mp4" loop autoPlay muted></video>
      <h1>JNK Industries </h1>
      <div className="row">
       <h2>
       {/* To be recognized in the industry we serve as the leading contract manufacturing supplier.   */}
        Website is under maintenance
       </h2>
      </div>
      {/* <button className="btn" style={{ cursor: "pointer" }} onClick={() => window.location = 'mailto:sales@jnkindustries.ca'}>
          Request a Quote
        </button> */}
      <div className="headerbg"></div>
    </header>
  );
}
export default Header; 
