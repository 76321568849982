// import About from '../components/About';
// import Services from '../components/Services';
// import Contact from '../components/Contact';

function Main() {
  return (
    <>
      <main>
        {/* <Services />
        <About />
        <Contact /> */}
      </main>
    </>
  );
}
export default Main;
